import React from 'react'
import { FaGithub, FaLinkedin } from 'react-icons/fa'
import { HiOutlineMail } from 'react-icons/hi'


const SocialMedia = () => {
  return (
    <div className="social">
      <div>
      <a href='https://www.linkedin.com/in/olha-chumak' target="_blank" rel="noreferrer" className="flex"><FaLinkedin /></a>
      </div>
      <div>
      <a href='https://github.com/loaymoolb' target="_blank" rel="noreferrer" className="flex"><FaGithub /></a>
      </div>
      <div>
      <a href="mailto:olyachumakk@gmail.com" target="_blank"  rel="noreferrer" className="flex"><HiOutlineMail /></a>
      </div>
    </div>
  )
}

export default SocialMedia